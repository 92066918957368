export enum NfcReaderErrorType {
  SCAN_TIMEOUT = "SCAN_TIMEOUT",
  SCAN_CANCEL = "SCAN_CANCEL",
  SCAN_FAILED = "SCAN_FAILED",
  SCAN_PARSING_FAILED = "SCAN_PARSING_FAILED",
  UNSUPPORTED = "UNSUPPORTED",
  DISABLED = "DISABLED",
  SCAN_ALREADY_IN_PROGRESS = "SCAN_ALREADY_IN_PROGRESS",
}

export class NfcReaderError extends Error {
  public readonly type: NfcReaderErrorType;

  constructor(type: NfcReaderErrorType) {
    super(type);
    this.type = type;
  }
}
